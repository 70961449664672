<template>
  <div class="order-detail" v-loading="loading">
    <div class="header">
      <divider-title text="订单详情" />
    </div>
    <div class="container">
      <div class="content">
        <div class="item">订单号：{{ orderId }}</div>
        <div class="item">{{item}}</div>
        <div class="item order-track">
          <span class="title">订单跟踪</span>
          <span
            class="time-item"
            v-for="(item, index) in copyTrackList"
            :key="index"
          >
              <template v-if='item'><span style="padding-right:10px;">{{item.t | dateTimeFormat}}</span>{{item.des}}</template>
          </span>
          <span
            class="time-item more"
            v-show="!isShowMoreTrack && orderInfo.pdatas.length > 1"
            @click="isShowMoreTrack = true"
          >
            展开更多
            <i class="el-icon-arrow-down"></i>
          </span>
        </div>
        <div class="ware-list">
          <div class="ware-header">
            <span class="ware-name">商品名称</span>
            <span class="count">数量</span>
            <span class="price">单价</span>
          </div>
          <div class="ware-item" v-for="(item, index) in orderInfo.products" :key="index">
            <img :src="item.product.pic.picUrl" alt v-if="item.product.overdue == 0"/>
            <img src="@/assets/images/guoqi.png" alt="" v-else>
            <div class="ware-info">
              <span class="warename">{{ item.product.title }}</span>
              <span>{{ item.product.drskudes }}</span>
              <span>{{ item.product.dskudes }}</span>
            </div>
            <div class="count">{{ item.product.pnum }}</div>
            <div class="price">{{ item.product.unitprice|toRMB }}</div>
          </div>
          <div class="ware-total">
            <div>商品总价：{{ orderInfo.totalfee | toRMB }}</div>
            <div v-if="orderInfo.totalfee - orderInfo.price != 0">优惠：￥{{ orderInfo.totalfee - orderInfo.price | toRMB }}</div>
            <div class="pay-amount" v-if="orderInfo.paymethod==1 && orderInfo.paystatus == 5">待尾款支付{{ orderInfo.price - orderInfo.haspaymoney| toRMB }}</div>
            <div class="pay-amount">实付金额：{{ orderInfo.haspaymoney | toRMB }}</div>
          </div>
        </div>
        <div class="consignee-information">
          <div class="consignee-header">收货人信息</div>
          <div class="information">
            <div class="left">
              <div>
                <span>姓名：</span>
                <span>{{ userInfo.name }}</span>
              </div>
              <div>
                <span>详细地址：</span>
                <span>{{ userInfo.city + " " + userInfo.adress }}</span>
              </div>
              <div>
                <span>联系电话：</span>
                <span>{{ userInfo.mobile }}</span>
              </div>
            </div>
            <div class="right">
              <div>
                <span>创建时间：</span>
                <span>{{ orderInfo.ct | dateTimeFormat }}</span>
              </div>
              <div v-if="orderInfo.payct">
                <span>付款时间：</span>
                <span>{{ orderInfo.payct | dateTimeFormat }}</span>
              </div>
              <div v-if="orderInfo.sct">
                <span>发货时间：</span>
                <span>{{ orderInfo.sct | dateTimeFormat }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="divide"></div>
        <div class="button-group">
           <div v-if="ostatus == 0" 
            @click="cancelorder(orderId)">取消订单</div>

             <div v-if="ostatus === 0"
             @click="topay(info)">去支付</div>
            
            <div 
            v-if="ostatus === 5000 || ostatus === 5010"
            @click="del" class="btn del" >
            删除订单</div>

            <div v-if="info.paymethod == 1 && info.paystatus == 5 && info.exchangestatus != 1" 
            @click="tolastpay(info)">支付尾款</div>

            <div v-if="ostatus  === 300 && info.appraise == 0"
            @click="evaluate">评价</div>

            <div v-if="ostatus  === 300 && info.appraise == 1">已评价</div>

            <div v-if="ostatus === 210"
            @click="receive(orderId)">确认收货</div>

            <div v-if="ex === 0  && refund === 0">
            <div v-if="ostatus === 99 || ostatus ===100 || ostatus === 110|| ostatus === 115|| ostatus === 120 "
            @click="godaddy(orderId)">申请退款</div>
            </div>

            <div v-if='ex === 1'
          @click="godadds(1)">
            退款申请中
          </div>
            <div v-if='ex === 10'
            @click="godadds(2)">
          >
            同意退款
          </div>
            <div v-if='ex === 110'
            @click="godadds(3)">
          >
            退款已完成
          </div>
            <div  v-if='ex === 210'
            @click="godadds(4)">
          >
            退款已拒绝
          </div>
           



        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dividerTitle from "@/components/dividerTitle";
import { ORDER_DETAIL, DEL_ORDER } from "../../service/order";
import {cancle,refund,sign} from '../../service/api'
export default {
  name: "OrderDetail",
  components: { dividerTitle },
  data() {
    return {
      loading: false,
      orderId: "",
      isShowMoreTrack: false,
      orderInfo: {
        totalPrice: 100000,
        payAmount: 88888,
        coupon: 2000,
        pdatas: [],
        products: [],
      },
      userInfo: {},
      item:'',
      ostatus:'',
      info:{},
      ex:'',
      refund:''
    }; 
  },
  computed: {
    copyTrackList() {
      let arr = [];
      if (this.isShowMoreTrack) {
        arr = this.orderInfo.pdatas.slice();
      } else {
        arr = this.orderInfo.pdatas.slice(0, 1);
      }
      return arr;
    }
  },
  created() {
    console.log(this.$route.query.orderId)
    this.orderId = this.$route.query.orderId;
    // console.log("this.orderId", this.orderId);
    this.info = this.$route.query.info
    // console.log(this.$route.query)
    this.getOrderDetail(this.orderId);

  },
  methods: {
      //退款
     godadds(i){
      if(i == 2){
        alert( "已同意退款" )
      }else if(i == 3){
        alert( "退款已完成" )
      }else if(i == 4){
        alert( "已拒绝退款" )
      }
    },
    getOrderDetail(orderid) {
      this.loading = true;
      ORDER_DETAIL({ orderid }).then(res => {
        this.loading = false;
        this.orderInfo = res.attachment.order;
        this.userInfo = this.orderInfo.to;
        this.ostatus = res.attachment.order.ostatus
        this.ex = res.attachment.order.exchangestatus
        this.refund = res.attachment.order.refund
        this.orderId = res.attachment.order.orderid
        // console.log(this.ostatus)
        // console.log("this.orderInfo", this.orderInfo.products);
        
     if (this.ostatus >= 90 && this.ostatus <= 100) {
        return this.item =  "待接收样本";
      } else if (this.ostatus == 130) {
        return this.item =  "待寄出样本";
      } else if (this.ostatus == 140) {
        return this.item =  "待碳化培育";
      } else if (this.ostatus > 140 && this.ostatus < 160) {
        return this.item =  "碳化培育中";
      } else if (this.ostatus >= 160 && this.ostatus < 190) {
        return this.item =  "国际认证中";
      } else if (this.ostatus == 190) {
        return this.item =  "待支付尾款";
      } else if (this.ostatus == 200) {
        return this.item =  "待发货";
      } else if (this.ostatus == 210) {
        return this.item =  "待收货";
      } else if (this.ostatus == 300) {
        return this.item =  "已签收";
      } else if (this.ostatus == 5000 || this.ostatus == 5010) {
        return this.item =  "已关闭";
      } else if (this.ostatus == 0) {
        return this.item = "待支付";
      } else {
        return this.item =  "";
      }
      });
    },




      //取消订单
  cancelorder(id){
      cancle({
        uid:this.$store.getters['user/getUid'],
        orderid:id,
        reason:'不买了'
      }).then((res)=>{
         if (res.status === 200) {
        this.$message({
          type: "success",
          message: "取消成功"
        });
        this.$router.go(-1);
      }
      })
  },

  //删除订单
    
  del() {
    DEL_ORDER({ orderid: this.orderId }).then(res => {
      if (res.status === 200) {
        this.$message({
          type: "success",
          message: "删除成功"
        });
        this.$router.go(-1);
      }
    });
  },
  //申请退款
  godaddy(id){
    refund({
      uid:this.$store.getters['user/getUid'],
      orderid:id,
      reason:'不买了'
    }).then((res)=>{
      // this.$message({ message: "申请成功！" });
      console.log(res)
      this.getOrderDetail(this.orderId)
    })
  },
  //支付尾款
  tolastpay(info){
      this.$store.dispatch('order/orderAffirmInfo', info)
          this.$router.push({
          path: '/confirmPay',
          query: {
            orderAffirmInfo: JSON.stringify(info),
            flag:0,
          }
      })
  },
  //去支付s
  topay(info){
    console.log(info)
      this.$store.dispatch('order/orderAffirmInfo', info)
          this.$router.push({
          path: '/confirmPay',
          query: {
            orderAffirmInfo: JSON.stringify(this.orderInfo)
          }
      })
  },
  //评价
  evaluate() {
      this.$router.push({
        path: "/orderevaluate",
        query: { orderId: this.orderId }
      });
    },
  //确定收货
  receive(id){
    sign({
      uid:this.$store.getters['user/getUid'],
      orderid:id
    }).then(res=>{
      console.log(res)
    })
  },



  }
};
</script>
<style lang="less" scoped>
.order-detail {
  background: #f5f5f5;
  box-shadow: 0px 4px 5px 2px #d6d6d6;
  .header {
    padding-top: 28px;
    height: 96px;
    background: #fff;
  }
  .container {
    padding: 20px 20px 86px 20px;
  }
  .content {
    padding: 15px 20px 0;
    background: #fff;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
    .item {
      font-size: 16px;
      line-height: 30px;
      padding: 25px 20px;
      border-bottom: 1px solid #cfcfcf; /*no*/
    }
    .order-track {
      padding-left: 74px;
      display: flex;
      flex-direction: column;
      .time-item {
        font-size: 14px;
        line-height: 2;
      }
      .time-item.more {
        cursor: pointer;
        margin-left: 44px;
      }
    }
    .ware-list {
      .ware-header {
        padding: 0px 36px 0 20px;
        margin-top: 50px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        background: #f5f5f5;
        display: flex;
        .count,
        .price {
          flex: 1;
          text-align: center;
        }
        .ware-name {
          width: 520px;
          text-align: left;
        }
      }
      .ware-item {
        width: 100%;
        // height: 140px;
        padding: 30px 36px 30px 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #cfcfcf; /*no*/
        img {
          width: 80px;
          height: 80px;
          border-radius: 8px;
          margin-right: 40px;
        }
        .ware-info {
          width: 400px;
          // height: 80px;
          display: flex;
          flex-direction: column;
          > span {
            font-size: 12px;
            line-height: 1.8;
            color: #999;
          }
          .warename {
            font-size: 14px;
            color: #000;
          }
        }
        .count,
        .price {
          flex: 1;
          text-align: center;
        }
      }
      .ware-total {
        padding: 8px 80px 42px 0;
        text-align: right;
        line-height: 2;
        font-size: 14px;
        color: #343434;
        .pay-amount {
          font-size: 16px;
        }
      }
    }
    .consignee-information {
      .consignee-header {
        padding-left: 20px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        background: #f5f5f5;
      }
      .information {
        padding: 25px 90px 25px 75px;
        // height: 130px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        > div {
          line-height: 2;
          font-size: 14px;
        }
        .left {
          > div {
            display: flex;
            span:first-child {
              min-width: 80px; /*no*/
            }
          }
        }
        .right {
          > div {
            display: flex;
            span:first-child {
              padding-right: 10px;
              min-width: 80px; /*no*/
            }
          }
        }
      }
    }
    .divide {
      height: 40px;
      background: #f5f5f5;
    }
    .button-group {
      display: flex;
      justify-content: space-around;
      padding: 50px 0 56px;
      div{
        width: 150px;
        height: 50px;
        background: #921d22;
        color: #fff;
        line-height: 50px;
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}
</style>
